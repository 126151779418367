export default class {
  constructor() {
    this.instance = [
      {
        typeAttendanceId:
          "A1" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / ABONO DE MULTA - PROMOÇÃO INDICAÇÃO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          classificationId: "00061",
          subject: "ABONO DE MULTA - PROMOÇÃO INDICAÇÃO",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-2" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / APROVEITAMENTO DE CURSO/CRÉDITO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00110",
          subject: "APROVEITAMENTO DE CURSO/CRÉDITO",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-3" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / TROCA DE MEIO DE PAGAMENTO - RECORRENTE PARA BOLETO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00105",
          subject: "TROCA DE MEIO DE PAGAMENTO - RECORRENTE PARA BOLETO",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-4" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / ADICIONAL NOTURNO/INCLUSÃO DE PERCENTUAL */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00109",
          subject: "ADICIONAL NOTURNO/INCLUSÃO DE PERCENTUAL",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-5" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / QUITAÇÃO DE CONTRATO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00107",
          subject: "QUITAÇÃO DE CONTRATO",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-6" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / NOTA FISCAL */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "4701",
          respColligateId: "1",
          classificationId: "00062",
          subject: "NOTA FISCAL",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
        },
      },

      {
        typeAttendanceId:
          "A1-7" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / TROCA DE DATA DE VENCIMENTO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00040",
          subject: "TROCA DE DATA DE VENCIMENTO",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-8" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / BOLSA EMPRESA CONVENIADA */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00067",
          subject: "BOLSA EMPRESA CONVENIADA",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-9" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / INCLUSÃO DE DISCIPLINA NATIVA - VALOR */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00108",
          subject: "INCLUSÃO DE DISCIPLINA NATIVA - VALOR",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-11" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / CAMPANHA RESGATE */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00070",
          subject: "CAMPANHA RESGATE",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-12" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / ESTORNO*/,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00072",
          subject: "ESTORNO",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },

      {
        typeAttendanceId:
          "A1-13" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / DEVOLUCAO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00071",
          subject: "DEVOLUCAO",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },
      {
        typeAttendanceId:
          "A1-14" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / ESTORNO - DRC */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00116",
          subject: "ESTORNO - DRC",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },
      {
        typeAttendanceId:
          "A1-15" /* ADMINISTRATIVO / AJUSTES FINANCEIROS / DEPARTAMENTO DE CONTRATOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3282",
          respColligateId: "1",
          classificationId: "00124",
          subject: "DEPARTAMENTO DE CONTRATOS",
          typeAttendanceId: "230",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
          priority: "",
        },
      },

      {
        typeAttendanceId: "A2" /* ADMINISTRATIVO / ALTERAÇÃO DE CADASTRO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3669",
          respColligateId: "1",
          classificationId: "00028",
          subject: "",
          typeAttendanceId: "231",
          groupAttendanceId: "24",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
          productId: "7",
          accessLevel: null,
          attachment: "",
          fileName: "",
        },
      },

      {
        typeAttendanceId: "A3" /* ADMINISTRATIVO / COMPRAS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "4041", // 4041
          respColligateId: "1",
          classificationId: "00016", // 00016
          subject: "",
          typeAttendanceId: "85", // 86
          groupAttendanceId: "8", // 8
          personId: "",
          requestText: "",
          productId: "3",
          accessLevel: null,
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A4" /* ADMINISTRATIVO / CORREÇÃO DE LANÇAMENTOS FINACEIROS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3589", //"3589"
          respColligateId: "1",
          productId: "7",
          classificationId: "00063", //fix data,
          subject: "",
          typeAttendanceId: "119", //fix data
          groupAttendanceId: "9", //fix data
          acessLevel: "2",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A5" /* ADMINISTRATIVO / DRC */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "1631", //"1631"
          respColligateId: "1",
          classificationId: "00019", //fix data,
          subject: "Negociação DRC",
          typeAttendanceId: "75", //fix data
          groupAttendanceId: "7", //fix data
          personId: "",
          requestText: "",
          acessLevel: "0",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A6" /* ADMINISTRATIVO / ESTOQUE */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3060", // 3060
          respColligateId: "1",
          classificationId: "00016", // 00016
          subject: "",
          typeAttendanceId: "86", // 86
          groupAttendanceId: "8", // 8
          personId: "",
          requestText: "",
          productId: "3",
          acessLevel: "2",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A7" /* ADMINISTRATIVO / JURIDICO / ANÁLISE DE DOCUMENTOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2945",
          respColligateId: "1",
          classificationId: "00076",
          subject: "JURÍDICO - ANÁLISE DE DOCUMENTOS",
          typeAttendanceId: "61",
          groupAttendanceId: "3",
          personId: "",
          requestText: "",
          productId: "8",
          accessLevel: "6",
          attachment: "",
          fileName: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A7-2" /* ADMINISTRATIVO / JURIDICO / CONTRATO DE DIREITOS AUTORAIS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2945",
          respColligateId: "1",
          classificationId: "00074",
          subject: "JURÍDICO - CONTRATO DE DIREITOS AUTORAIS",
          typeAttendanceId: "61",
          groupAttendanceId: "3",
          personId: "",
          requestText: "",
          productId: "8",
          accessLevel: "6",
          attachment: "",
          fileName: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A7-3" /* ADMINISTRATIVO / JURIDICO / CONTRATO DE PRESTAÇÃO DE SERVIÇOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2945",
          respColligateId: "1",
          classificationId: "00075",
          subject: "JURÍDICO - CONTRATO DE PRESTAÇÃO DE SERVIÇOS",
          typeAttendanceId: "61",
          groupAttendanceId: "3",
          personId: "",
          requestText: "",
          productId: "8",
          accessLevel: "6",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A7-4" /* ADMINISTRATIVO / JURIDICO / PROCESSOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2945",
          respColligateId: "1",
          classificationId: "00073",
          subject: "",
          typeAttendanceId: "61",
          groupAttendanceId: "3",
          personId: "",
          requestText: "",
          productId: "8",
          accessLevel: "6",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A7-5" /* ADMINISTRATIVO / JURIDICO / CONVÊNIOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2945",
          respColligateId: "1",
          classificationId: "00091",
          subject: "",
          typeAttendanceId: "215",
          groupAttendanceId: "8",
          personId: "",
          requestText: "",
          productId: "8",
          accessLevel: "6",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A8" /* ADMINISTRATIVO / MANUTENÇÃO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3842", // 3842
          respColligateId: "1",
          classificationId: "00016", // 00016
          subject: "",
          typeAttendanceId: "87", // 87
          groupAttendanceId: "8", // 8
          personId: "",
          requestText: "",
          productId: "3",
          acessLevel: "2",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A9" /* ADMINISTRATIVO / NORMATIVAS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3719",
          respColligateId: "1",
          classificationId: "00117",
          subject: "NORMATIVAS",
          typeAttendanceId: "133",
          groupAttendanceId: "10",
          acessLevel: null,
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A10" /* ADMINISTRATIVO / NUMERARIOS / AÇÃO COMERCIAL */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "4462",
          respColligateId: "1",
          classificationId: "00086",
          subject: "",
          typeAttendanceId: "227",
          groupAttendanceId: "9",
          personId: "",
          requestText: "",
          productId: "7",
          acessLevel: "2",
          attachment: "",
          fileName: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A10-2" /* ADMINISTRATIVO / NUMERARIOS / CORREIOS E MALOTES */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "4462",
          respColligateId: "1",
          classificationId: "00089",
          subject: "",
          typeAttendanceId: "32",
          groupAttendanceId: "9",
          personId: "",
          requestText: "",
          productId: "7",
          acessLevel: "2",
          attachment: "",
          fileName: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A10-3" /* ADMINISTRATIVO / NUMERARIOS / SOLICITAÇÃO DE REEMBOLSO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "4462",
          respColligateId: "1",
          classificationId: "00088",
          subject: "",
          typeAttendanceId: "32",
          groupAttendanceId: "9",
          personId: "",
          requestText: "",
          productId: "7",
          acessLevel: "2",
          attachment: "",
          fileName: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A10-4" /* ADMINISTRATIVO / NUMERARIOS / VIAGENS E DESLOCAMENTOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "4462",
          respColligateId: "1",
          classificationId: "00087",
          subject: "",
          typeAttendanceId: "32",
          groupAttendanceId: "9",
          personId: "",
          requestText: "",
          productId: "7",
          acessLevel: "2",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A11" /* ADMINISTRATIVO / PROJETOS ARQUITETÔNICOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2934",
          respColligateId: "1",
          classificationId: "00118",
          subject: "",
          typeAttendanceId: "93",
          groupAttendanceId: "8",
          personId: "",
          requestText: "",
          accessLevel: "2",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A12" /* ADMINISTRATIVO / PROBLEMAS ESTRUTURAIS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "", // this.respAttendantId.toString(),
          respColligateId: "1",
          classificationId: "00093", //fix data,
          subject: "",
          typeAttendanceId: "222", //fix data
          groupAttendanceId: "8", //fix data
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A13" /* ADMINISTRATIVO / TI / SUPORTE / AJUSTE DE USUÁRIO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "1933",
          respColligateId: "1",
          classificationId: "00096",
          subject: "AJUSTE DE USUÁRIO",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A13-2" /* ADMINISTRATIVO / TI / SUPORTE / ALTERAÇÃO DE PROCESSO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2426",
          respColligateId: "1",
          classificationId: "00120",
          subject: "ALTERAÇÃO DE PROCESSO",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A13-3" /* ADMINISTRATIVO / TI / SUPORTE / AUTOMATIZAÇÃO DE PROCESSO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2426",
          respColligateId: "1",
          classificationId: "00119",
          subject: "AUTOMATIZAÇÃO DE PROCESSO",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId: "A13-4" /* ADMINISTRATIVO / TI / SUPORTE / CUBOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2426",
          respColligateId: "1",
          classificationId: "00097",
          subject: "",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A13-5" /* ADMINISTRATIVO / TI / SUPORTE / ERROS REPORTADOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2426",
          respColligateId: "1",
          classificationId: "00102",
          subject: "ERROS REPORTADOS",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A13-6" /* ADMINISTRATIVO / TI / INFRAESTRUTURA/TELEFONIA */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2427",
          respColligateId: "1",
          classificationId: "00100",
          subject: "",
          typeAttendanceId: "228",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A13-7" /* ADMINISTRATIVO / TI / SUPORTE / OUTRAS SOLICITAÇÕES */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2426",
          respColligateId: "1",
          classificationId: "00121",
          subject: "OUTRAS SOLICITAÇÕES",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A13-8" /* ADMINISTRATIVO / TI / SUPORTE / RELATÓRIOS */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2426",
          respColligateId: "1",
          classificationId: "00098",
          subject: "",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A13-9" /* ADMINISTRATIVO / TI / SUPORTE / UNIFICAÇÃO DE RA / PESSOA */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "1933",
          respColligateId: "1",
          classificationId: "00099",
          subject: "UNIFICAÇÃO DE RA / PESSOA",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A13-10" /* ADMINISTRATIVO / TI / SUPORTE / CRIAÇÃO DE ATENDIMENTO/PROTOCOLO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2426",
          respColligateId: "1",
          classificationId: "00125",
          subject: "CRIAÇÃO DE ATENDIMENTO/PROTOCOLO",
          typeAttendanceId: "229",
          groupAttendanceId: "23",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A14" /* ADMINISTRATIVO / MANUTENÇÃO PREVENTIVA / INFRAESTRUTURA*/,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "2427",
          respColligateId: "1",
          classificationId: "00122",
          subject: "MANUTENÇÃO PREVENTIVA - INFRAESTRUTURA / TELEFONIA",
          typeAttendanceId: "235",
          groupAttendanceId: "8",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A14-1" /* ADMINISTRATIVO / MANUTENÇÃO PREVENTIVA / PREDIAL*/,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3842",
          respColligateId: "1",
          classificationId: "00123",
          subject: "MANUTENÇÃO PREVENTIVA - PREDIAL",
          typeAttendanceId: "232",
          groupAttendanceId: "8",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId:
          "A15-1" /* ADMINISTRATIVO / ATENDIMENTO NETEP / ALUNO QI */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "5798",
          productId: "10",
          respColligateId: "1",
          classificationId: "00126",
          subject: "NETEP - ALUNO QI",
          accessLevel: null,
          typeAttendanceId: "241",
          groupAttendanceId: "10",
          ra: "",
          requestText: "",
          clientType: "Aluno",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A15-2" /* ADMINISTRATIVO / ATENDIMENTO NETEP / ALUNO EXTERNO */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "5798",
          productId: "10",
          respColligateId: "1",
          classificationId: "00128",
          subject: "NETEP - ALUNO EXTERNO",
          accessLevel: null,
          typeAttendanceId: "241",
          groupAttendanceId: "10",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
      {
        typeAttendanceId:
          "A15-3" /* ADMINISTRATIVO / ATENDIMENTO NETEP / SOLICITAÇÃO INTERNA */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "5798",
          productId: "10",
          respColligateId: "1",
          classificationId: "00129",
          subject: "NETEP - SOLICITAÇÃO INTERNA",
          accessLevel: null,
          typeAttendanceId: "241",
          groupAttendanceId: "10",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },

      {
        typeAttendanceId: "A16" /* ADMINISTRATIVO / DEMANDA COMERCIAL */,
        data: {
          colligateId: "1",
          locationId: "",
          respAttendantId: "3868",
          productId: "10",
          respColligateId: "1",
          classificationId: "00136",
          subject: "DEMANDA COMERCIAL",
          accessLevel: null,
          typeAttendanceId: "246",
          groupAttendanceId: "7",
          personId: "",
          requestText: "",
          clientType: "Pessoa",
          attendantId: "",
        },
      },
    ];
  }

  getDataByAttendanceId(typeAttendanceId) {
    for (let i = 0; i < this.instance.length; i++) {
      if (this.instance[i].typeAttendanceId == typeAttendanceId)
        return this.instance[i];
    }
  }
}
